import React from "react";
import styled from "styled-components";

import { colors } from "../../utils/colors";
import { smallScreen, mediumScreen } from "../../utils/media";
import { StyledModal } from "../styles/styles";
import { Button } from "semantic-ui-react";

import useWindowDimensions from "../../hooks/windowDimention.hook";

const Description = styled.p`
  font-size: 18px;
  text-align: center;
  color: ${colors.darkGray};
  padding: 25px 0;
  margin-bottom: 0;
  padding: 30px 0 !important;
`;
const Title = styled(Description)`
  font-size: 28px;
  text-align: center;
`;
const StyledButton = styled(Button)`
  color: ${colors.white} !important;
  background-color: ${colors.green} !important;
  margin: 0 auto !important;
  border: 0px solid ${colors.green} !important;
  @media ${smallScreen} {
    width: 220px;
    height: 40px;
  }
  @media ${mediumScreen} {
    width: 320px;
    height: 45px;
  }
`;

const StyledPromptButton = styled(StyledButton)`
  margin: 5px !important;
  @media ${smallScreen} {
    width: 95px;
    height: 40px;
  }
  @media ${mediumScreen} {
    width: 140px;
    height: 45px;
  }
`;

const StyledPromptButtonRed = styled(StyledPromptButton)`
  background-color: ${colors.red} !important;
`;
const PromptWrapper = styled.div`
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

const StyledPromptBtnWrapper = styled.div`
  margin: auto;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: row;
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 40px !important;
  @media ${smallScreen} {
    max-height: 600px !important;
  }
  @media ${mediumScreen} {
    max-height: 700px !important;
  }
  height: ${(props) => props.height - 45}px;
`;

const GameWinContent = ({ msg, grandPrizeCoins }) => {
  const [open, setOpen] = React.useState(true);
  const { height } = useWindowDimensions();

  return (
    <StyledModal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
    >
      <ModalContent>
        <PromptWrapper>
          <Title>Congratulations!</Title>
          {grandPrizeCoins > 0 ? (
            <>
              <Description>
                {`You won ${grandPrizeCoins} coins. YEAH!`}
              </Description>
              <Description>{`${msg}`}</Description>
            </>
          ) : null}
          <StyledPromptBtnWrapper>
            <StyledPromptButtonRed
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </StyledPromptButtonRed>
          </StyledPromptBtnWrapper>
        </PromptWrapper>
      </ModalContent>
    </StyledModal>
  );
};

export default GameWinContent;
